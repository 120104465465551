import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './newsletter.module.css'
import Layout from '../components/layout/Layout'
import SEO from '../components/seo'
import AgnosticLink from '../components/AgnosticLink'
import {ArchiveList} from '../components/archive-list/archive-list'

export default class Newsletter extends React.Component {
  render() {
    const { data } = this.props
    const articles = data.allMarkdownRemark.edges.map((e) => e.node)
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout>
        <article className={styles.page}>
          <SEO title={`Newsletter | ${siteTitle}`} />
          <h1 className={styles.title}>Newsletter Archive</h1>
          <p>
            Welcome to the Barbarian Meets Coding newsletter. A weekly
            collection of <strong> handcrafted emails </strong> that give you
            more insight into what I'm working on, what I'm thinking about, what
            I'm learning... A sort of meta-barbarian-meets-coding where you can
            find out more about what goes behind the scenes. Featuring awesome,
            high quality, carefully crafted articles on programming, web
            development, JavaScript, developer productivity, tools, UX and even
            exclusive content like{' '}
            <strong> free or early versions of my books </strong>.
          </p>
          <p>
            <strong>Haven't subscribed to the newsletter yet? </strong>{' '}
          </p>
          <p>
            Then{' '}
            <AgnosticLink href={'http://eepurl.com/bR8FHb'}>
              sign up
            </AgnosticLink>{' '}
            to my super duper awesome inner circle and take a peek at what lies
            behind the curtain. Here are some of the past editions so you can
            get a taste.
          </p>
          <h2>Past Editions</h2>
          <ArchiveList articles={articles} classes={styles.archiveList} />
        </article>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query NewsletterQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { draft: { eq: false }, newsletter: { eq: true } }
      }
    ) {
      edges {
        node {
          excerpt
          html
          fields {
            slug
          }
          frontmatter {
            date
            title
            categories
          }
          timeToRead
        }
      }
    }
  }
`
